<template>
  <div>
    <!-- <Loader /> -->
    <section class="sign-in-page">
      <b-container
        fluid
      >
        <div
          class="row"
          style="margin-top:50px;"
        >
          <div class="col-12 col-sm-4 offset-sm-4 align-self-center">
            <router-view />
          </div>
        </div>
      </b-container>
    </section>
  </div>
</template>
<script>
// import Loader from '@/components/core/loader/Loader'
import logo from '@/assets/images/logo-white.png'
import { core } from '@/config/pluginInit'
import bgImage from '@/assets/images/login/login-2.jpg'
// import Swiper from '@/components/core/slider/Swiper'
// import SwiperSlide from '@/components/core/slider/SwiperSlide'
export default {
  name: 'NotFoundLayout',
  components: {
    // Loader
    // Swiper,
    // SwiperSlide
  },
  data () {
    return {
      options: {
        slidesPerView: 1,
        loop: true
      },
      logo: logo,
      bgImageURL: bgImage
    }
  },
  mounted () {
    core.index()
  }
}
</script>
  <style>
    @import url("../assets/css/custom.css");
  </style>
